<template>
  <div class="new_home">
    <el-row :gutter="20">
      <el-col :span="6">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>我的日程</span>
            <el-button style="float: right; padding: 3px 0" type="text"
              >MORE>></el-button
            >
          </div>
          <div>
            <ele-calendar :data="datedef" :prop="prop"> </ele-calendar>
          </div>
        </el-card>
      </el-col>
      <el-col :span="12">
        <!-- 新闻 -->
        <el-card class="box-card news-card">
          <el-row style="height: 100%">
            <el-col :span="12" style="height: 100%">
              <div id="banner" style="height: 100%">
                <!--动态将图片轮播图的容器高度设置成与图片一致-->
                <el-carousel direction="vertical" style="height: 100%">
                  <!--遍历图片地址,动态生成轮播图-->
                  <el-carousel-item v-for="(item, i) in imgList" :key="i">
                    <div style="height: 97%">
                      <img
                        v-lazy="item.url"
                        alt
                        style="width: 100%; height: 93%"
                      />
                      <div class="img_text">{{ item.text }}</div>
                    </div>
                  </el-carousel-item>
                </el-carousel>
              </div>
            </el-col>
            <el-col :span="12" style="height: 100%">
              <div style="position: relative; height: 100%">
                <el-tabs
                  type="border-card"
                  v-model="activeName"
                  style="height: 100%"
                >
                  <el-tab-pane label="集团新闻" name="集团新闻">
                    <ul class="news-list-group">
                      <li class="news-list-group-item">
                        一图读懂2020年上半年空调及...<span
                          style="float: right; color: #ababab"
                          >09-12</span
                        >
                      </li>
                      <li class="news-list-group-item">
                        一图读懂2020年上半年空调及...<span
                          style="float: right; color: #ababab"
                          >09-12</span
                        >
                      </li>
                      <li class="news-list-group-item">
                        一图读懂2020年上半年空调及...<span
                          style="float: right; color: #ababab"
                          >09-12</span
                        >
                      </li>
                      <li class="news-list-group-item">
                        一图读懂2020年上半年空调及...<span
                          style="float: right; color: #ababab"
                          >09-12</span
                        >
                      </li>
                      <li class="news-list-group-item">
                        一图读懂2020年上半年空调及...<span
                          style="float: right; color: #ababab"
                          >09-12</span
                        >
                      </li>
                      <li class="news-list-group-item">
                        一图读懂2020年上半年空调及...<span
                          style="float: right; color: #ababab"
                          >09-12</span
                        >
                      </li>
                      <li class="news-list-group-item">
                        一图读懂2020年上半年空调及...<span
                          style="float: right; color: #ababab"
                          >09-12</span
                        >
                      </li>
                      <li class="news-list-group-item">
                        一图读懂2020年上半年空调及...<span
                          style="float: right; color: #ababab"
                          >09-12</span
                        >
                      </li>
                      <!-- <li>一图读懂2020年上半年空调及制冷设备相关上市企业财报</li>
             <li>一图读懂2020年上半年空调及制冷设备相关上市企业财报</li> -->
                    </ul>
                  </el-tab-pane>
                  <el-tab-pane label="行业动态" name="行业动态"
                    >行业动态</el-tab-pane
                  >
                  <el-tab-pane label="展会培训" name="展会培训"
                    >展会培训</el-tab-pane
                  >
                  <!-- <el-tab-pane label="定时任务补偿" name="fourth" style='float:left'>定时任务补偿</el-tab-pane> -->
                </el-tabs>
                <el-button
                  style="position: absolute; right: 10px; top: 3px"
                  type="text"
                  >MORE>></el-button
                >
              </div>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
      <!-- 通知公告 -->
      <el-col :span="6">
        <el-card class="box-card">
          <div slot="header" class="tz-clearfix">
            <span>通知公告</span>
            <el-button style="float: right; padding: 3px 0" type="text"
              >MORE>></el-button
            >
          </div>
          <div>
            <ul class="news-list-group">
              <li class="news-list-group-item">
                一图读懂2020年上半年空调及...<span
                  style="float: right; color: #ababab"
                  >09-12</span
                >
              </li>
              <li class="news-list-group-item">
                一图读懂2020年上半年空调及...<span
                  style="float: right; color: #ababab"
                  >09-12</span
                >
              </li>
              <li class="news-list-group-item">
                一图读懂2020年上半年空调及...<span
                  style="float: right; color: #ababab"
                  >09-12</span
                >
              </li>
              <li class="news-list-group-item">
                一图读懂2020年上半年空调及...<span
                  style="float: right; color: #ababab"
                  >09-12</span
                >
              </li>
              <li class="news-list-group-item">
                一图读懂2020年上半年空调及...<span
                  style="float: right; color: #ababab"
                  >09-12</span
                >
              </li>
              <li class="news-list-group-item">
                一图读懂2020年上半年空调及...<span
                  style="float: right; color: #ababab"
                  >09-12</span
                >
              </li>
              <li class="news-list-group-item">
                一图读懂2020年上半年空调及...<span
                  style="float: right; color: #ababab"
                  >09-12</span
                >
              </li>
              <li class="news-list-group-item">
                一图读懂2020年上半年空调及...<span
                  style="float: right; color: #ababab"
                  >09-12</span
                >
              </li>
            </ul>
          </div>
        </el-card>
      </el-col>
    </el-row>

    <el-row :gutter="20" style="margin-top: 20px">
      <el-col :span="6">
        <el-card class="box-card cai-card">
          <div>
            <ul class="fastBlock">
              <li
                style="
                  border-bottom: 1px solid #ddd;
                  border-right: 1px solid #ddd;
                "
              >
                <div
                  :style="{
                    background:
                      'url(' +
                      require('../assets/txl.png') +
                      ') no-repeat left center',
                    'padding-left': '30px',
                  }"
                >
                  通讯录
                </div>
              </li>
              <li style="border-bottom: 1px solid #ddd">
                <div
                  :style="{
                    background:
                      'url(' +
                      require('../assets/bsclxfs.png') +
                      ') no-repeat left center',
                    'padding-left': '23px',
                  }"
                >
                  区域分公司联系方式
                </div>
              </li>
              <li
                style="
                  border-bottom: 1px solid #ddd;
                  border-right: 1px solid #ddd;
                "
              >
                <div
                  :style="{
                    background:
                      'url(' +
                      require('../assets/grtxl.png') +
                      ') no-repeat left center',
                    'padding-left': '30px',
                  }"
                >
                  个人通讯录
                </div>
              </li>
              <li style="border-bottom: 1px solid #ddd">
                <div
                  :style="{
                    background:
                      'url(' +
                      require('../assets/yhhhcx.png') +
                      ') no-repeat left center',
                    'padding-left': '23px',
                  }"
                >
                  银行行号查询
                </div>
              </li>
              <li
                style="
                  border-bottom: 1px solid #ddd;
                  border-right: 1px solid #ddd;
                "
              >
                <div
                  :style="{
                    background:
                      'url(' +
                      require('../assets/zgsw.png') +
                      ') no-repeat left center',
                    'padding-left': '30px',
                  }"
                >
                  职工书屋
                </div>
              </li>
              <li style="border-bottom: 1px solid #ddd">
                <div
                  :style="{
                    background:
                      'url(' +
                      require('../assets/ydlylb.png') +
                      ') no-repeat left center',
                    'padding-left': '23px',
                  }"
                >
                  用电量一览表
                </div>
              </li>
              <li
                style="
                  border-bottom: 1px solid #ddd;
                  border-right: 1px solid #ddd;
                "
              >
                <div
                  :style="{
                    background:
                      'url(' +
                      require('../assets/dyjwhb.png') +
                      ') no-repeat left center',
                    'padding-left': '30px',
                  }"
                >
                  打印机维护表
                </div>
              </li>
              <li style="border-bottom: 1px solid #ddd">
                <div
                  :style="{
                    background:
                      'url(' +
                      require('../assets/rzwp.png') +
                      ') no-repeat left center',
                    'padding-left': '23px',
                  }"
                >
                  人资网盘
                </div>
              </li>
              <li
                style="
                  border-bottom: 1px solid #ddd;
                  border-right: 1px solid #ddd;
                "
              >
                <div
                  :style="{
                    background:
                      'url(' +
                      require('../assets/zzjg.png') +
                      ') no-repeat left center',
                    'padding-left': '30px',
                  }"
                >
                  组织架构图
                </div>
              </li>
              <li style="border-bottom: 1px solid #ddd">
                <div
                  :style="{
                    background:
                      'url(' +
                      require('../assets/wjfwq.png') +
                      ') no-repeat left center',
                    'padding-left': '23px',
                  }"
                >
                  常用工具下载
                </div>
              </li>
              <li
                style="
                  border-bottom: 1px solid #ddd;
                  border-right: 1px solid #ddd;
                "
              >
                <div
                  :style="{
                    background:
                      'url(' +
                      require('../assets/yjx.png') +
                      ') no-repeat left center',
                    'padding-left': '30px',
                  }"
                >
                  意见箱
                </div>
              </li>
              <li style="border-bottom: 1px solid #ddd">
                <div
                  :style="{
                    background:
                      'url(' +
                      require('../assets/grdkpxx.png') +
                      ') no-repeat left center',
                    'padding-left': '23px',
                  }"
                >
                  格瑞德开票信息
                </div>
              </li>
              <li
                style="
                  border-bottom: 1px solid #ddd;
                  border-right: 1px solid #ddd;
                "
              >
                <div
                  :style="{
                    background:
                      'url(' +
                      require('../assets/ybxz.png') +
                      ') no-repeat left center',
                    'padding-left': '30px',
                  }"
                >
                  样本文件下载
                </div>
              </li>
              <li style="border-bottom: 1px solid #ddd">
                <div
                  :style="{
                    background:
                      'url(' +
                      require('../assets/lrfc.png') +
                      ') no-repeat left center',
                    'padding-left': '23px',
                  }"
                >
                  利润分成及税收计算
                </div>
              </li>
              <li
                style="
                  border-bottom: 1px solid #ddd;
                  border-right: 1px solid #ddd;
                "
              >
                <div
                  :style="{
                    background:
                      'url(' +
                      require('../assets/txl.png') +
                      ') no-repeat left center',
                    'padding-left': '30px',
                  }"
                >
                  通讯录
                </div>
              </li>
              <li style="border-bottom: 1px solid #ddd">
                <div
                  :style="{
                    background:
                      'url(' +
                      require('../assets/txl.png') +
                      ') no-repeat left center',
                    'padding-left': '23px',
                  }"
                >
                  区域分公司联系方式
                </div>
              </li>
            </ul>
          </div>
        </el-card>
      </el-col>
      <el-col :span="12">
        <!-- 流程 -->
        <el-card class="box-card liu-card">
          <div style="position: relative; height: 100%">
            <el-tabs
              type="border-card"
              v-model="lactiveName"
              style="height: 100%"
            >
              <el-tab-pane name="待阅">
                <span slot="label">待 阅(10)</span>
                <el-table :data="tableData" style="width: 100%">
                  <el-table-column prop="date" label="标题" width="500">
                  </el-table-column>
                  <el-table-column prop="name" label="类型" width="180">
                  </el-table-column>
                  <el-table-column prop="address" label="时间">
                  </el-table-column>
                </el-table>
              </el-tab-pane>
              <el-tab-pane name="待办"
                ><span slot="label">待 办(10)</span>
                待办
              </el-tab-pane>
              <el-tab-pane label="已办" name="已办">已 办</el-tab-pane>
              <el-tab-pane label="交期提醒" name="交期提醒"
                >交期提醒</el-tab-pane
              >
            </el-tabs>
            <el-button
              style="position: absolute; right: 10px; top: 3px"
              type="text"
              >MORE>></el-button
            >
          </div>
        </el-card>
      </el-col>
      <!-- 论坛 -->
      <el-col :span="6">
        <el-card class="box-card">
          <div slot="header" class="lt-clearfix">
            <span>论坛</span>
            <el-button style="float: right; padding: 3px 0" type="text"
              >MORE>></el-button
            >
          </div>
          <div>
            <ul class="news-list-group">
              <li class="news-list-group-item">
                一图读懂2020年上半年空调及...<span
                  style="float: right; color: #ababab"
                  >09-12</span
                >
              </li>
              <li class="news-list-group-item">
                一图读懂2020年上半年空调及...<span
                  style="float: right; color: #ababab"
                  >09-12</span
                >
              </li>
              <li class="news-list-group-item">
                一图读懂2020年上半年空调及...<span
                  style="float: right; color: #ababab"
                  >09-12</span
                >
              </li>
              <li class="news-list-group-item">
                一图读懂2020年上半年空调及...<span
                  style="float: right; color: #ababab"
                  >09-12</span
                >
              </li>
              <li class="news-list-group-item">
                一图读懂2020年上半年空调及...<span
                  style="float: right; color: #ababab"
                  >09-12</span
                >
              </li>
              <li class="news-list-group-item">
                一图读懂2020年上半年空调及...<span
                  style="float: right; color: #ababab"
                  >09-12</span
                >
              </li>
              <li class="news-list-group-item">
                一图读懂2020年上半年空调及...<span
                  style="float: right; color: #ababab"
                  >09-12</span
                >
              </li>
              <li class="news-list-group-item">
                一图读懂2020年上半年空调及...<span
                  style="float: right; color: #ababab"
                  >09-12</span
                >
              </li>
              <!-- <li>一图读懂2020年上半年空调及制冷设备相关上市企业财报</li>
             <li>一图读懂2020年上半年空调及制冷设备相关上市企业财报</li> -->
            </ul>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <!-- 待阅 待办 -->
    <el-row :gutter="20" style="margin-top: 20px">
      <el-col :span="4">
        <el-card class="box-card">
          <div
            slot="header"
            :style="{
              background:
                'url(' +
                require('../assets/dbtj.png') +
                ') no-repeat left center',
              'padding-left': '23px',
              'font-size': '15px',
              color: '#175094',
              'font-weight': 'bold',
            }"
          >
            <span>待办统计</span>
            <el-button style="float: right; padding: 3px 0" type="text"
              >MORE>></el-button
            >
          </div>
          <div>
            <ul class="cur-list-group">
              <li class="cur-list-group-item">
                石秀英<span style="float: right">171</span>
              </li>
              <li class="cur-list-group-item">
                石秀英<span style="float: right">171</span>
              </li>
              <li class="cur-list-group-item">
                石秀英<span style="float: right">171</span>
              </li>
              <li class="cur-list-group-item">
                石秀英<span style="float: right">171</span>
              </li>
              <li class="cur-list-group-item">
                石秀英<span style="float: right">171</span>
              </li>
              <li class="cur-list-group-item">
                石秀英<span style="float: right">171</span>
              </li>
            </ul>
          </div>
        </el-card>
      </el-col>
      <el-col :span="4">
        <el-card class="box-card">
          <div
            slot="header"
            :style="{
              background:
                'url(' +
                require('../assets/dbtj.png') +
                ') no-repeat left center',
              'padding-left': '23px',
              'font-size': '15px',
              color: '#175094',
              'font-weight': 'bold',
            }"
          >
            <span>待阅统计</span>
            <el-button style="float: right; padding: 3px 0" type="text"
              >MORE>></el-button
            >
          </div>
          <div>
            <ul class="cur-list-group">
              <li class="cur-list-group-item">
                石秀英<span style="float: right">171</span>
              </li>
              <li class="cur-list-group-item">
                石秀英<span style="float: right">171</span>
              </li>
              <li class="cur-list-group-item">
                石秀英<span style="float: right">171</span>
              </li>
              <li class="cur-list-group-item">
                石秀英<span style="float: right">171</span>
              </li>
              <li class="cur-list-group-item">
                石秀英<span style="float: right">171</span>
              </li>
              <li class="cur-list-group-item">
                石秀英<span style="float: right">171</span>
              </li>
            </ul>
          </div>
        </el-card>
      </el-col>

      <el-col :span="8">
        <el-card class="box-card">
          <div
            slot="header"
            :style="{
              background:
                'url(' +
                require('../assets/tsjy.png') +
                ') no-repeat left center',
              'padding-left': '23px',
              'font-size': '15px',
              color: '#175094',
              'font-weight': 'bold',
            }"
          >
            <span>站内邮件</span>
            <el-button style="float: right; padding: 3px 0" type="text"
              >MORE>></el-button
            >
          </div>
          <div>
            <el-table :data="emailData" style="width: 100%">
              <el-table-column
                prop="date"
                label="主题"
                width="200"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column prop="name" label="发件人"> </el-table-column>
              <el-table-column prop="address" label="到达时间">
              </el-table-column>
              <el-table-column prop="state" label="状态">
                <template>
                  <el-button size="mini" type="success">已读</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-card>
      </el-col>
      <!-- 工作日志 -->
      <el-col :span="8">
        <el-card class="box-card">
          <div
            slot="header"
            :style="{
              background:
                'url(' +
                require('../assets/gzrz.png') +
                ') no-repeat left center',
              'padding-left': '23px',
              'font-size': '15px',
              color: '#175094',
              'font-weight': 'bold',
            }"
          >
            <span>工作日志</span>
            <el-button style="float: right; padding: 3px 0" type="text"
              >MORE>></el-button
            >
          </div>
          <div>
            <el-table :data="workData" style="width: 100%">
              <el-table-column
                prop="date"
                label="日志内容"
                width="200"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column prop="name" label="姓名"> </el-table-column>
              <el-table-column prop="address" label="日期"> </el-table-column>
            </el-table>
          </div>
        </el-card>
      </el-col>
    </el-row>

    <!-- 招聘信息 -->
    <el-row :gutter="20" style="margin-top: 20px">
      <el-col :span="8" style="height: 100%">
        <el-card class="box-card z-card">
          <div style="position: relative; height: 100%">
            <el-tabs
              type="border-card"
              v-model="zctiveName"
              style="height: 100%"
            >
              <el-tab-pane label="招聘信息" name="招聘信息">
                <el-table :data="zhaoData" style="width: 100%">
                  <el-table-column
                    prop="date"
                    label="标题"
                    show-overflow-tooltip
                  >
                  </el-table-column>
                  <el-table-column prop="name" label="时间"> </el-table-column>
                  <el-table-column prop="address" label="报名">
                  </el-table-column>
                  <el-table-column prop="address" label="状态">
                  </el-table-column>
                  <el-table-column prop="address" label="报名数量">
                  </el-table-column>
                </el-table>
              </el-tab-pane>
              <el-tab-pane label="招标通知" name="招标通知"
                >招标通知</el-tab-pane
              >
            </el-tabs>
            <el-button
              style="position: absolute; right: 10px; top: 3px"
              type="text"
              >MORE>></el-button
            >
          </div>
        </el-card>
      </el-col>

      <el-col :span="10">
        <el-card class="box-card">
          <div
            slot="header"
            :style="{
              background:
                'url(' +
                require('../assets/ssfw.png') +
                ') no-repeat left center',
              'padding-left': '23px',
              'font-size': '15px',
              color: '#175094',
              'font-weight': 'bold',
            }"
          >
            <span>售后服务产品质量追溯</span>
            <el-button style="float: right; padding: 3px 0" type="text"
              >MORE>></el-button
            >
          </div>
          <div>
            <el-table :data="shouData" style="width: 100%">
              <el-table-column prop="date" label="客户" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="name" label="项目名称"> </el-table-column>
              <el-table-column prop="address" label="产品名称">
              </el-table-column>
              <el-table-column prop="state" label="提货日期"> </el-table-column>
              <el-table-column prop="state" label="责任公司"> </el-table-column>
            </el-table>
          </div>
        </el-card>
      </el-col>
      <!-- 工作日志 -->
      <el-col :span="6">
        <el-card class="box-card tou-card">
          <div
            slot="header"
            :style="{
              background:
                'url(' +
                require('../assets/tsjy.png') +
                ') no-repeat left center',
              'padding-left': '23px',
              'font-size': '15px',
              color: '#175094',
              'font-weight': 'bold',
            }"
          >
            <span>投诉建议</span>
            <el-button style="float: right; padding: 3px 0" type="text"
              >MORE>></el-button
            >
          </div>
          <div class="personal-relation" style="font-size: 16px">
            <div class="relation-item">联系方式</div>
          </div>
          <div class="personal-relation">
            <div class="relation-item">
              格瑞德监事长信息直通平台(15505345442) 微信
            </div>
          </div>
          <div class="personal-relation">
            <div class="relation-item">投诉电话: 0534-2730316</div>
          </div>
          <div class="personal-relation">
            <div class="relation-item">投诉邮箱: 315@gradgroup.com6</div>
          </div>
          <div class="personal-relation">
            <div class="relation-item">
              董事会意见箱: 集团西大门、东车棚、办公楼北门及北厂食堂设有
            </div>
          </div>
          <div class="personal-relation">
            <div class="relation-item">QQ: 1583042711</div>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import eleCalendar from "ele-calendar";
import "ele-calendar/dist/vue-calendar.css"; //引入css
export default {
  data() {
    return {
      datedef: [
        { date: "2018-06-30", content: null, cid: null },
        { date: "2018-06-26", content: null, cid: null },
      ],
      prop: "date", //对应日期字段名
      // 图片父容器高度
      bannerHeight: 100,
      // 浏览器宽度
      screenWidth: 0,
      imgList: [
        {
          url: require("../assets/banner1.png"),
          text: "开展专业培训师培训 助内训师技能提升",
        },
      ],
      activeName: "集团新闻",
      lactiveName: "待阅",
      zctiveName: "招聘信息",
      tableData: [
        {
          date: "格瑞德9月篮球赛进入第二天",
          name: "新闻",
          address: "9-15",
        },
      ],
      emailData: [
        {
          date: "格瑞德9月篮球赛进入第二天",
          name: "新闻",
          address: "9-15",
          state: "已读",
        },
      ],
      workData: [],
      zhaoData: [],
      shouData: [],
    };
  },
  components: {
    eleCalendar,
  },
  methods: {},
};
</script>
<style scoped>
.clearfix {
  background: url(../assets/wdrc.png) no-repeat left center;
  padding-left: 20px;
  font-size: 15px;
  color: #175094;
  font-weight: bold;
}
.tz-clearfix {
  background: url(../assets/tzgg.png) no-repeat left center;
  padding-left: 20px;
  font-size: 15px;
  color: #175094;
  font-weight: bold;
}
.lt-clearfix {
  background: url(../assets/tlq.png) no-repeat left center;
  padding-left: 20px;
  font-size: 15px;
  color: #175094;
  font-weight: bold;
}
.box-card {
  height: 350px;
}
.img_text {
  width: 100%;
  word-break: keep-all; /* 不换行 */
  white-space: nowrap; /* 不换行 */
  overflow: hidden;
  text-overflow: ellipsis;
  color: white;
  height: 7%;
  background-color: #c7c7c7;
}
.news-list-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}
.news-list-group-item {
  position: relative;
  display: block;
  padding: 8px 0px;
  margin-bottom: -1px;
  background-color: #fff;
  font-size: 13px;
  color: #282828;
  cursor: pointer;
  width: 100%;
  height: 30px;
  line-height: 22px;
  overflow: hidden;
  word-break: keep-all;
  text-overflow: ellipsis;
}
.fastBlock {
  width: 100%;
  padding-left: 0px;
}
.fastBlock li {
  min-height: 50px;
  height: 50px;
  width: 50%;
  padding: 20px 10px;
  background-color: #fff;
  float: left;
  list-style: none;
  cursor: pointer;
}
.cur-list-group-item {
  position: relative;
  display: block;
  padding: 5px 10px;
  margin-bottom: -1px;
  background-color: #fff;
  font-size: 13px;
  color: #282828;
  cursor: pointer;
}
.personal-relation {
  font-size: 13px;
  /* padding: 0px 5px 15px; */
  margin-right: 1px;
  width: 100%;
}
.relation-item {
  padding: 12px;
}
</style>
<style>
.new_home .el-card__header {
  padding: 12px 21px;
  border-bottom: 1px solid #ebeef5;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.new_home .el-carousel__container {
  position: relative;
  height: 100%;
}
.news-card .el-card__body {
  padding: 3px 0 0 10px;
  height: 100%;
}
.liu-card .el-card__body {
  padding: 0px 0px 0 0px;
  height: 100%;
}
.cai-card .el-card__body {
  padding: 0px 0px 20px 0px;
  height: 100%;
}
.z-card .el-card__body {
  padding: 0;
  height: 100%;
}
.tou-card .el-card__body {
  padding: 0;
}
.new_home .el-table th > .cell {
  color: #282828;
}
.new_home .el-table__empty-block {
  display: none;
}
.new_home .el-table td,
.el-table th.is-leaf {
  border: none;
}
</style>